
import { defineComponent } from "vue";

export default defineComponent({
  name: "WidgetInfoImage",
  props: {
    widget: Object,
    isButton: {
      type: Boolean,
      default: false
    }
  },
});
