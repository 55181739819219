
import { defineComponent } from "vue";

export default defineComponent({
  name: "WidgetImageText",
  props: {
    widget: Object,
    isButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      hoverableItemIndex: null
    }
  },
  methods: {
    onProductClick(link: string) {
      window.location.href = link;
    }
  },
});
