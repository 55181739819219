import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "business-site business-site--product" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Preloader = _resolveComponent("Preloader")
  const _component_WidgetImageText = _resolveComponent("WidgetImageText")
  const _component_WidgetCarousel = _resolveComponent("WidgetCarousel")
  const _component_WidgetHtmlEditor = _resolveComponent("WidgetHtmlEditor")
  const _component_WidgetOurClients = _resolveComponent("WidgetOurClients")
  const _component_WidgetInfoImage = _resolveComponent("WidgetInfoImage")
  const _component_WidgetInfoImageIntro = _resolveComponent("WidgetInfoImageIntro")
  const _component_WidgetTextDescImageButton = _resolveComponent("WidgetTextDescImageButton")
  const _component_WidgetCta = _resolveComponent("WidgetCta")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (
        !_ctx.$parent.$parent.isPageHeaderTopMenuLoaded ||
          !_ctx.$parent.$parent.isPageHeaderSubMenuLoaded ||
          !_ctx.$parent.$parent.isPageWidgetsLoaded
      )
      ? (_openBlock(), _createBlock(_component_Preloader, {
          key: 0,
          class: "preloader--fullscreen"
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.$parent.$parent.widgets, (widget, index) => {
      return (_openBlock(), _createBlock(_Fragment, null, [
        (widget.widgetName === 'WidgetImageText')
          ? (_openBlock(), _createBlock(_component_WidgetImageText, {
              key: `widget-${widget.id}-${index}`,
              widget: widget
            }, null, 8, ["widget"]))
          : (widget.widgetName === 'WidgetCarousel')
            ? (_openBlock(), _createBlock(_component_WidgetCarousel, {
                key: `widget-${widget.id}-${index}`,
                widget: widget
              }, null, 8, ["widget"]))
            : (widget.widgetName === 'WidgetHtmlEditor')
              ? (_openBlock(), _createBlock(_component_WidgetHtmlEditor, {
                  key: `widget-${widget.id}-${index}`,
                  widget: widget
                }, null, 8, ["widget"]))
              : (widget.widgetName === 'WidgetOurClients')
                ? (_openBlock(), _createBlock(_component_WidgetOurClients, {
                    key: `widget-${widget.id}-${index}`,
                    widget: widget
                  }, null, 8, ["widget"]))
                : (widget.widgetName === 'WidgetInfoImage')
                  ? (_openBlock(), _createBlock(_component_WidgetInfoImage, {
                      key: `widget-${widget.id}-${index}`,
                      widget: widget
                    }, null, 8, ["widget"]))
                  : (widget.widgetName === 'WidgetInfoImageButton')
                    ? (_openBlock(), _createBlock(_Fragment, { key: 5 }, [
                        (widget.is_button_disabled)
                          ? (_openBlock(), _createBlock(_component_WidgetInfoImage, {
                              key: `widget-${widget.id}-${index}`,
                              widget: widget
                            }, null, 8, ["widget"]))
                          : (_openBlock(), _createBlock(_component_WidgetInfoImageIntro, {
                              key: `widget-${widget.id}-${index}`,
                              widget: widget
                            }, null, 8, ["widget"]))
                      ], 64))
                    : (widget.widgetName === 'WidgetTdib')
                      ? (_openBlock(), _createBlock(_component_WidgetTextDescImageButton, {
                          key: `widget-${widget.id}-${index}`,
                          widget: widget
                        }, null, 8, ["widget"]))
                      : (widget.widgetName === 'WidgetCta')
                        ? (_openBlock(), _createBlock(_component_WidgetCta, {
                            key: `widget-${widget.id}-${index}`,
                            widget: widget
                          }, null, 8, ["widget"]))
                        : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}