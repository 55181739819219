
import { defineComponent } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import Preloader from "@/components/ui/Preloader.vue";

export default defineComponent({
  name: "ModalVideo",
  components: { CloseIcon, Preloader },
  props: {
    modalName: {
      type: String,
      default: ""
    },
    videoSrc: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isVideoLoaded: false
    };
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  unmounted() {
    document.body.classList.remove("no-scroll");
  }
});
