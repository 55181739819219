
import {defineComponent} from "vue";

export default defineComponent({
  name: "WidgetHtmlEditor",
  props: {
    widget: Object
  },
  computed: {
    getHtml(): string {
      // @ts-ignore
      if (this.responsiveState === "mobile") {
        const html = this.widget?.html;
        const regexp = /font-size:\s\d+/g;

        return html.replaceAll(regexp, this.changeFontSize);
      } else {
        return this.widget?.html;
      }
    }
  },
  methods: {
    changeFontSize(match: string) {
      // @ts-ignore
      let fontSize = +match.match(/\d+/g);

      if (fontSize >= 80) {
        fontSize = fontSize / 2.666;
      } else if (fontSize >= 72) {
        fontSize = fontSize / 2.571;
      } else if (fontSize >= 60) {
        fontSize = fontSize / 2.5;
      } else if (fontSize >= 36) {
        fontSize = fontSize / 2;
      } else if (fontSize >= 30) {
        fontSize = fontSize / 1.875;
      } else if (fontSize > 16) {
        fontSize = 16;
      }

      return "font-size: " + fontSize;
    }
  }
});
