
import { defineComponent } from "vue";
import WidgetHtmlEditor from "@/components/widgets/WidgetHtmlEditor.vue";
import WidgetInfoImage from "@/components/widgets/WidgetInfoImage.vue";
import WidgetTextDescImageButton from "@/components/widgets/WidgetTextDescImageButton.vue";
import WidgetCta from "@/components/widgets/WidgetCta.vue";
import Preloader from "@/components/ui/Preloader.vue";
import WidgetCarousel from  "@/components/widgets/WidgetCarousel.vue";
import WidgetImageText from "@/components/widgets/WidgetImageText.vue";
import WidgetOurClients from "@/components/widgets/WidgetOurClients.vue";
import WidgetInfoImageIntro from "@/components/widgets/WidgetInfoImageIntro.vue";

export default defineComponent({
  name: "ProductPage",
  components: {
    WidgetImageText,
    WidgetCarousel,
    WidgetHtmlEditor,
    WidgetInfoImage,
    WidgetTextDescImageButton,
    WidgetCta,
    Preloader,
    WidgetOurClients,
    WidgetInfoImageIntro
  }
});
