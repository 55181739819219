import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-popup__header" }
const _hoisted_2 = { class: "modal-popup__header-text" }
const _hoisted_3 = { class: "modal-popup__body" }
const _hoisted_4 = { class: "modal-popup__video-outer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")
  const _component_Preloader = _resolveComponent("Preloader")

  return (_openBlock(), _createBlock("div", {
    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$parent.modalVideoOpened = false)),
    class: "modal-popup-wrapper overlay overlay--white"
  }, [
    _createVNode("div", {
      onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
      class: "modal-popup modal-popup--video modal-popup--lg"
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.modalName), 1),
        _createVNode("span", {
          class: "modal-popup__close-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$parent.modalVideoOpened = false))
        }, [
          _createVNode(_component_close_icon, { class: "tablet-up" })
        ])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          (!_ctx.isVideoLoaded)
            ? (_openBlock(), _createBlock(_component_Preloader, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode("video", {
            class: "modal-popup__video",
            src: _ctx.videoSrc,
            autoplay: "",
            controls: "",
            onLoadeddata: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isVideoLoaded = true))
          }, null, 40, ["src"])
        ])
      ])
    ])
  ]))
}