
import { defineComponent } from "vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import ModalVideo from "@/components/ui/ModalVideo.vue";

export default defineComponent({
  name: "WidgetCarousel",
  components: {
    Swiper,
    SwiperSlide,
    ModalVideo
  },
  props: {
    widget: Object,
    isButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      swiperBreakpoints: {
        580: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      },
      slider: null,
      slideSize: null as null | number,
      // modalSlideIndex: 0,
      currentSlideTitle: "",
      currentSlideVideoSrc: "",
      modalVideoOpened: false
    }
  },
  methods: {
    onSwiper(swiper: any) {
      this.slideSize = swiper.slides[0].swiperSlideSize / 2;
      this.slider = swiper;
    },
    slideSizeUpdate() {
      const slider = this.slider as any;
      this.slideSize = slider.slides[0].swiperSlideSize / 2;
    },
    onSlideClick(slide: {}) {
      //@ts-ignore
      this.currentSlideTitle = slide.title;
      //@ts-ignore
      this.currentSlideVideoSrc = slide.video_src;
      this.modalVideoOpened = true;
    },
    toSlidePrev() {
      const slider = this.slider as any;
      slider.slidePrev();
    },
    toSlideNext() {
      const slider = this.slider as any;
      slider.slideNext();
    }
  },
  mounted() {
    window.addEventListener("resize", this.slideSizeUpdate);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.slideSizeUpdate);
  }
});
