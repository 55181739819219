import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "section section-products" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "products-items d-flex" }
const _hoisted_4 = {
  class: "products-item__image-outer",
  style: {"height":"auto"}
}
const _hoisted_5 = { class: "products-item__title" }
const _hoisted_6 = { class: "products-item__desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.widget.image_items, (item, index) => {
          return (_openBlock(), _createBlock("div", {
            key: `products-item-${index}`,
            class: [
            'products-item',
            item.classes,
            _ctx.hoverableItemIndex !== null && 'is-hoverable',
            _ctx.hoverableItemIndex !== null &&
              _ctx.hoverableItemIndex === index &&
              'is-hoverable-active'
          ],
            onClick: ($event: any) => (_ctx.onProductClick(item.url)),
            onMouseover: ($event: any) => (_ctx.hoverableItemIndex = index),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hoverableItemIndex = null))
          }, [
            _createVNode("div", _hoisted_4, [
              _createVNode("img", {
                class: "products-item__image",
                src: item.image_src,
                alt: `Image ${item.title}`
              }, null, 8, ["src", "alt"])
            ]),
            _createVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
            _createVNode("div", _hoisted_6, _toDisplayString(item.description), 1)
          ], 42, ["onClick", "onMouseover"]))
        }), 128))
      ])
    ])
  ]))
}