import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "widget__container container text-center" }
const _hoisted_2 = {
  key: 0,
  class: "widget__button-outer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "widget widget--cta",
    style: { 'background-color': _ctx.widget.background_color }
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: "widget__title section-title",
        style: { color: _ctx.widget.title_color },
        innerHTML: _ctx.widget.title
      }, null, 12, ["innerHTML"]),
      (_ctx.widget.button_text && _ctx.widget.button_url)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("a", {
              class: "widget__button btn btn--primary",
              href: _ctx.widget.button_url,
              style: {
            'background-color': _ctx.widget.button_color,
            color: _ctx.widget.button_text_color
          }
            }, _toDisplayString(_ctx.widget.button_text), 13, ["href"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}