import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section section-our-clients" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "heading-bordered" }
const _hoisted_4 = { class: "heading-bordered__text" }
const _hoisted_5 = { class: "customers-items d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.widget.title), 1)
      ]),
      _createVNode("ul", _hoisted_5, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.widget.image_items, (item, index) => {
          return (_openBlock(), _createBlock("li", {
            key: `client-item-${index}`,
            class: "customers-item"
          }, [
            _createVNode("img", {
              class: "products-item__image",
              src: item.image_src,
              alt: `Image ${item.img_alt}`
            }, null, 8, ["src", "alt"])
          ]))
        }), 128))
      ])
    ])
  ]))
}