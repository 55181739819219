
import { defineComponent } from "vue";

export default defineComponent({
  name: "WidgetImageText",
  props: {
    widget: Object,
    isButton: {
      type: Boolean,
      default: false
    },
  },
});
