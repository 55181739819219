import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "widget widget--html-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: "widget__container container",
      innerHTML: _ctx.getHtml
    }, null, 8, ["innerHTML"])
  ]))
}