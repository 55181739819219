import { createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "widget__container container text-center" }
const _hoisted_2 = { class: "widget__image-outer" }
const _hoisted_3 = {
  key: 0,
  class: "widget__button-outer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "widget widget--tdib",
    style: { 'background-color': _ctx.widget.background_color }
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: "widget__title section-title",
        style: { color: _ctx.widget.title_color },
        innerHTML: _ctx.widget.title
      }, null, 12, ["innerHTML"]),
      _createVNode("div", {
        class: "widget__desc",
        style: { color: _ctx.widget.description_color }
      }, _toDisplayString(_ctx.widget.description), 5),
      _createVNode("div", _hoisted_2, [
        _createVNode("img", {
          class: "widget__image",
          src: _ctx.widget.image_src,
          alt: _ctx.widget.img_alt
        }, null, 8, ["src", "alt"])
      ]),
      (_ctx.widget.button_text && _ctx.widget.button_url)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode("a", {
              class: "widget__button btn btn--primary",
              href: _ctx.widget.button_url,
              style: {
            'background-color': _ctx.widget.button_color,
            color: _ctx.widget.button_text_color
          },
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args)), ["stop","prevent"]))
            }, _toDisplayString(_ctx.widget.button_text), 13, ["href"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}