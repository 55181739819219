import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: [
        'widget__container',
        'container',
        'd-flex'
      ] }
const _hoisted_2 = { class: "section-top__inner" }
const _hoisted_3 = {
  key: 0,
  class: "section-top__image-outer"
}
const _hoisted_4 = { class: "section-top__button-outer" }
const _hoisted_5 = { class: "section-top__button-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "widget widget--info-image section-top",
    style: { 'background-color': _ctx.widget.background_color }
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", {
          class: "section-top__title",
          style: {
            color: _ctx.widget.title_color,
            'font-size':
              _ctx.responsiveState !== 'mobile' && _ctx.responsiveState !== 'tablet'
                ? _ctx.widget.title_font_size + 'px'
                : ''
          },
          innerHTML: _ctx.widget.title
        }, null, 12, ["innerHTML"]),
        (!_ctx.widget.image_or_video && _ctx.widget.image_src && _ctx.responsiveState !== 'mobile')
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("img", {
                class: "section-top__image",
                src: _ctx.widget.image_src,
                alt: _ctx.widget.img_alt
              }, null, 8, ["src", "alt"])
            ]))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: "section-top__subtitle",
          style: { color: _ctx.widget.description_color },
          innerHTML: _ctx.widget.description
        }, null, 12, ["innerHTML"]),
        _createVNode("div", _hoisted_4, [
          _createVNode("a", {
            class: "section-top__button btn btn--primary",
            href: _ctx.widget.button_url,
            style: {
              'background-color': _ctx.widget.button_color,
              color: _ctx.widget.button_text_color
            },
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.buttonClick && _ctx.buttonClick(...args)), ["stop","prevent"]))
          }, _toDisplayString(_ctx.widget.button_text), 13, ["href"]),
          _createVNode("span", _hoisted_5, _toDisplayString(_ctx.widget.text_near_button), 1)
        ])
      ])
    ])
  ], 4))
}