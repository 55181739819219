import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "widget__block widget__block--video"
}
const _hoisted_2 = {
  key: 1,
  class: "widget__block widget__block--image"
}
const _hoisted_3 = { class: "widget__block widget__block--text" }
const _hoisted_4 = {
  key: 0,
  class: "widget__index-number"
}
const _hoisted_5 = {
  key: 1,
  class: "widget__button-outer"
}
const _hoisted_6 = { class: "btn-near-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "widget widget--info-image",
    style: { 'background-color': _ctx.widget.background_color }
  }, [
    _createVNode("div", {
      class: [
        'widget__container',
        'container',
        'd-flex',
        _ctx.widget.image_position && 'flex-reverse'
      ]
    }, [
      (_ctx.widget.image_or_video && _ctx.widget.video_src)
        ? (_openBlock(), _createBlock("div", _hoisted_1, [
            _createVNode("video", {
              class: "widget__video",
              src: _ctx.widget.video_src,
              autoplay: "",
              loop: "",
              muted: "",
              playsinline: ""
            }, null, 8, ["src"])
          ]))
        : (!_ctx.widget.image_or_video && _ctx.widget.image_src)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("img", {
                class: "widget__image",
                src: _ctx.widget.image_src,
                alt: _ctx.widget.img_alt
              }, null, 8, ["src", "alt"])
            ]))
          : _createCommentVNode("", true),
      _createVNode("div", _hoisted_3, [
        (_ctx.widget.index_number)
          ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.widget.index_number), 1))
          : _createCommentVNode("", true),
        _createVNode("div", {
          class: "widget__title section-title",
          style: {
            color: _ctx.widget.title_color,
            'font-size':
              _ctx.responsiveState !== 'mobile' && _ctx.responsiveState !== 'tablet'
                ? _ctx.widget.title_font_size + 'px'
                : ''
          },
          innerHTML: _ctx.widget.title
        }, null, 12, ["innerHTML"]),
        _createVNode("div", {
          class: "widget__desc",
          style: { color: _ctx.widget.description_color },
          innerHTML: _ctx.widget.description
        }, null, 12, ["innerHTML"]),
        (_ctx.isButton)
          ? (_openBlock(), _createBlock("div", _hoisted_5, [
              _createVNode("a", {
                class: "widget__button btn btn--primary",
                href: _ctx.widget.button_url,
                style: {
              'background-color': _ctx.widget.button_color,
              color: _ctx.widget.button_text_color
            }
              }, _toDisplayString(_ctx.widget.button_text), 13, ["href"]),
              _createVNode("span", _hoisted_6, _toDisplayString(_ctx.widget.text_near_button), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 4))
}