
import { defineComponent } from "vue";

export default defineComponent({
  name: "WidgetInfoImage",
  props: {
    widget: Object
  },
  methods: {
    buttonClick() {
      const btnUrl = this.widget?.button_url as string;
      if (btnUrl.indexOf("login#/sign-up") > 0) {
        const signupPlaceClick = 'Hero Banner';

        if (this.$gtm && this.$gtm.enabled()) {
          window.dataLayer?.push({
            'event': 'gifted',
            'name': 'signup_click',
            'signup_place_click': signupPlaceClick,
          });
        }

        const gtmData = JSON.parse(localStorage.getItem('gtmData') ?? '{}');
        gtmData.authType = 'signup';
        gtmData.clickPlace = signupPlaceClick;
        localStorage.setItem('gtmData', JSON.stringify(gtmData));
      }

      window.location.href = btnUrl;
    }
  }
});
